import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import SongSearch from '../components/SongSearch';

function Shoutouts() {
    return (
        <div></div>
    );
}

export default Shoutouts;