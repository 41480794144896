import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import SongSearch from '../components/SongSearch';

function SongRequests() {
    return (
        <div></div>
    );
}

export default SongRequests;