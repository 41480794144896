import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function About() {
    document.title = "O nas - Radiowęzeł";
    return (
        <div></div>
    );
}

export default About;